<template v-editable="blok">
  <BaseButton
    :id="blok.id"
    :outlined="isOutlined"
    :link="isLink"
    :full-width="blok.mobile_full_width"
    :title="blok.text"
    @handle-click="buttonScroll"
  >
    <template #text>
      {{ blok.text }}
    </template>
  </BaseButton>
</template>

<script lang="ts" setup>
import { scrollTo } from '@/utils/helpers'
import type { SbButtonScrollStoryblok } from '@/types'
interface Props {
  blok: SbButtonScrollStoryblok
}

const props = defineProps<Props>()

const buttonScroll = () => {
  if (props.blok.selector) {
    return scrollTo(`#${props.blok.selector}`, props.blok.offset)
  }
}

const isOutlined = computed(() => props.blok.options === 'outlined')
const isLink = computed(() => props.blok.options === 'link')
</script>
